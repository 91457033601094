<div id="wrapper" [ngClass]="{'toggled': isToggledNav}">
  <!-- Page Content -->
  <div id="page-content-wrapper" [ngClass]="{'not-header': !(isShowHeader | async) }">
    <div class="page-headers" *ngIf="isShowHeader | async">
      <div class="left-menu d-flex ml-3 pt-2 h-65px">
        <img class="w-44px h-44px img" src="assets/images/logo.png">
        <div class="left-header d-flex">
          <div class="d-flex flex-column ml-2 mt-1">
            <a class="h3 text-white text-decoration-none m-0 p-0" [routerLink]="['/tablet-app']">{{"TITLE_PAGE" |translate}}</a>
            <p class="m-0 p-0 small font-weight-bold">
              {{ environmentName === 'Development' ? ('Development' | translate) : ('Production' | translate) }}
            </p>
          </div>
          <span class="title-header ml-3 eclipse"
                [appTooltip]="(studySelected?.name | translate) + (siteSelected ? ' | ' + siteSelected.name + '(' + siteSelected.site_code + ')' : '')">
          {{studySelected?.name |translate}} {{siteSelected ? ' | '+siteSelected.name+'('+ siteSelected.site_code +')' : '' }}</span>
        </div>
      </div>

      <div class="user-info cursor-pointer d-flex">
        <ul class="nav justify-content-end h-56px">
          <li class="nav-item pt-2">
            <div ngbDropdown class="d-inline-block" *ngIf="multiLang">
              <a class="nav-link text-white" ngbDropdownToggle>
                <i class="fas fa-globe fs18 mr-1"></i>
                {{i18nUse}}
                <i class="fas fa-angle-down"></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1_1" class="dropdown-menu rounded">
                <div *ngFor="let lang of i18nList" ngbDropdownItem
                  [ngClass]="{'active': lang.code == appConfig.getLang()}" (click)="changeLanguage(lang)">{{lang.name}}</div>
              </div>
            </div>
          </li>
          <li class="nav-item pt-2">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link text-white" ngbDropdownToggle>
                <i class="fas fa-user-circle fs18 mr-1"></i>
                {{ authService.currentUserValue.user.name }}
                <i class="fas fa-angle-down"></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1_1" class="dropdown-menu rounded">
                <div ngbDropdownItem (click)="changePassword()">
                  <i class="fas fa-key"></i><span class="ml-2">{{ 'COMMON.CHANGE_PASSWORD' |translate }}</span>
                </div>
                <div ngbDropdownItem (click)="logout()">
                  <i class="fas fa-power-off"></i><span class="ml-2">{{ 'COMMON.LOGOUT' | translate }}</span>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>

    <div class="content-wrapper" [class]="isToggledNav==true ? 'overlay-show' :'' ">
      <div class="overlay" *ngIf="isToggledNav==true" (click)="isToggledNav =false"></div>
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
