import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../services/api-service/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSession} from '../../../models/user-session.model';
import {NavMenuList} from '../../../configs/menu-list';
import {I18NList} from '../../../configs/i18n-list';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NavMenuInterface} from 'src/app/interfaces/nav-menu.interface';
import {environment} from 'src/environments/environment';
import {ManagerStudySelectApi} from 'src/app/services/api-service/manager-study-select.api.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {APP_CONFIG} from 'src/app/configs/app-config';
import {GlobalDataService} from 'src/app/services/global-data.service';
import {BehaviorService} from 'src/app/services/behavior.service';

@Component({
  selector: 'app-manager-layout',
  templateUrl: './manager-layout.component.html',
  styleUrls: ['./manager-layout.component.scss'],
  preserveWhitespaces: false,
})
export class ManagerLayoutComponent implements OnInit, OnDestroy {
  isToggledNav = false;
  interval: any;
  itemName = '';
  currentUser: UserSession;
  menuList = NavMenuList.MENU_MANAGER;
  i18nList = I18NList;
  i18nUse = '';
  multiLang = true;
  titleHeader = 'eConsent';
  env = environment;
  appConfig = APP_CONFIG;
  environmentName: string;
  studySelected = null;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    protected managerStudySelectApi: ManagerStudySelectApi,
    protected globalDataService: GlobalDataService,
    protected behaviorService: BehaviorService,
    private activeRouter: ActivatedRoute
  ) {
    this.processStudyWorking();
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang) {
      lang = this.i18nList[0];
    }
    this.i18nUse = lang.name;

    this.behaviorService.changeLanguage(lang.code);

    this.menuList.map((menu: any) => {
      if (menu.url === this.router.url) {
        this.globalDataService.setMetaTitlePage(menu.name);
      }
    });
    this.environmentName = environment.environmentName;
  }

  changeLanguage(lang: any): void {
    this.i18nUse = lang.name;
    this.behaviorService.changeLanguage(lang.code);
  }

  gotoDashboard(): void {
    this.globalDataService.setMetaTitlePage('TITLE_PAGE');
  }

  changeMenu(menu): void {
    this.globalDataService.setMetaTitlePage(menu.name);
  }

  checkPermissionMenu(item): boolean {
    return this.globalDataService.hasAnyPermissions(item.permissions);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  logout(): void {
    this.globalDataService.checkFormChange().subscribe((allowChange) => {
      if (allowChange) {
        this.managerStudySelectApi.clearStudyWorking();
        this.authService.logout().subscribe(success => {
          if (success) {
            this.router.navigateByUrl('/login', {replaceUrl: true}).then();
          } else {
            this.globalDataService.showErrmessage('ERROR_LOGOUT');
          }
        });
      }
    });
  }

  changePassword(): void {
    this.router.navigateByUrl('/manager/change-password', {replaceUrl: true}).then();
  }

  processStudyWorking(): void {
    this.studySelected = this.managerStudySelectApi.getStudyWorking(false);
  }

  get isHasStudyWorking(): Observable<boolean> {
    return this.managerStudySelectApi.isHasStudyWorking
      .pipe(
        take(1),
        map((res: boolean) => {
          if (res) {
            this.studySelected = this.managerStudySelectApi.getStudyWorking(false);
            return true;
          }
          this.studySelected = null;
          return false;
        })
      );
  }
}
