import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import { APP_CONFIG } from 'src/app/configs/app-config';

export class QuickAccessForm {
  /* By the system's requirement, we will show error message when:
  * a control is touched, or
  * a control is dirty, or
  * the form is submitted
  *
  * FormGroup cannot catch the submitted event,  so you have to manually change the form to touched mode when submitting.
  * */
  static isShowErrorControl(form: FormGroup, controlName: string): boolean {
    return (form?.get(controlName).touched)
      && !!form?.get(controlName).errors;
  }

  /* This function will convert a control's error to a message
  * To use this function, you have to create an i18n entry
  * The i18n message must be in one of list format (all Uppercase):
  * controlName.errors.errorName
  * controlName.errorName
  * common.errors.errorName
  * common.errorName
  * errors.errorName
  * errorName
  * */
  static getErrorControlMsg(form: FormGroup, controlName: string, errors: Array<string>, parentMsg: string,
                            translate?: TranslateService, optionsControl = {}): string {
    return QuickAccessForm.isShowErrorControl(form, controlName)
      ? errors.map(err => {
        if (form.hasError(err.toLowerCase(), controlName)) {
          if (!translate){
            return err;
          }

          const pr = parentMsg ? parentMsg + '.' : '';

          // get control name text
          const key_nameField = (`${pr}FIELDS.${controlName}`).toUpperCase();
          const key_name = (`${pr}${controlName}`).toUpperCase();
          const key_nameCommon = (`COMMON.${controlName}`).toUpperCase();

          let controlNameDis = translate.instant(key_nameField);
          if (controlNameDis === key_nameField || controlNameDis === ''){
            controlNameDis = translate.instant(key_name);
            if (controlNameDis === key_name || controlNameDis === ''){
              controlNameDis = translate.instant(key_nameCommon);
              if (controlNameDis === key_nameCommon || controlNameDis === ''){
                controlNameDis = translate.instant(controlName);
                if (controlNameDis === controlName || controlNameDis === ''){
                  controlNameDis = '';
                }
              }
            }
          }

          let options = optionsControl[err.toLowerCase()] || {};
          options.controlName = controlNameDis;

          // get message
          // {screen}.errors.{controlName}.{err} > >> {screen}.errors.{err} >> {screen}.{err} >> common.errors.{err} >> common.{err} > errors.{err} >> {err}
          const key_messScreenControlNameErrors = (`${pr}ERRORS.${controlName}.${err}`).toUpperCase();
          const key_messScreenErrors = (`${pr}ERRORS.${err}`).toUpperCase();
          const key_messScreen = ( `${pr}${err}`).toUpperCase();
          const key_messCommonErrors = ( `COMMON.ERRORS.${err}`).toUpperCase();
          const key_messCommon = ( `COMMON.${err}`).toUpperCase();
          const key_messErrors = ( `ERRORS.${err}`).toUpperCase();
          const key_mess = ( err).toUpperCase();

          let message = translate.instant(key_messScreenControlNameErrors, options);
          if (message === key_messScreenControlNameErrors || message === ''){
            message = translate.instant(key_messScreenErrors, options);
            if (message === key_messScreenErrors || message === ''){
              message = translate.instant(key_messScreen, options);
              if (message === key_messScreen || message === ''){
                message = translate.instant(key_messCommonErrors, options);
                if (message === key_messCommonErrors || message === ''){
                  message = translate.instant(key_messCommon, options);
                  if (message === key_messCommon || message === ''){
                    message = translate.instant(key_messErrors, options);
                    if (message === key_messErrors || message === ''){
                      message = translate.instant(key_mess, options);
                    }
                  }
                }
              }
            }
          }


          // return message + '<br \>';
          return message;
        }
        return '';
      }).join('\n').trim()
      : '';
  }
}
