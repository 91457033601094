<div id="wrapper" [ngClass]="{'toggled': isToggledNav}">
  <div id="sidebar-wrapper">
    <div class="flexbox-sidebar" [ngClass]="{'background-flexbox': isToggledNav}">
      <a [routerLink]="'/manager'" class="text-decoration-none"
        routerLinkActive="active" (click)="gotoDashboard()">
        <div class="header-logo text-center">
          <div class="d-flex align-items-center">
            <img class="w-45px h-45px img rounded-0 mr-2" src="assets/images/logo.png">
            <div class="d-flex flex-column logo padding-top-5">
              <h3 class="text-white m-0" style="text-align: left;">eConsent</h3>
              <h4 class="m-0 small" style="text-align: left;">
                {{ environmentName === 'Development' ? ('Development' | translate) : ('Production' | translate) }}
              </h4>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="menu-left" *ngIf="isHasStudyWorking | async">
      <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
        <ng-container *ngFor="let menu of menuList">
          <li *ngIf="checkPermissionMenu(menu)" (click)="changeMenu(menu)">
            <a class="item-menu" [routerLink]="menu.url" routerLinkActive="active">
              <span class="custom-icon"><i class="{{menu.icon}}"></i></span>
              <span class="text">{{menu.name | translate}}</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="page-headers">
      <div class="left-header ml-3 d-flex">
        <span class="btn btn-toggle custom-btn" (click)="isToggledNav = !isToggledNav">
          <i class="fas fa-bars"></i>
        </span >
        <a [routerLink]="'/manager/study-select'" *ngIf="studySelected" class="title-header ml-3 eclipse"
           [appTooltip]="studySelected?.name |translate">{{studySelected?.name |translate}} </a>
        <!-- <a class="title-header ml-1" [routerLink]="'/manager/study-select'" *ngIf="studySelected">
          <i class=" fas fa-pencil-alt fs18"></i> {{studySelected?.id}}
        </a> -->
        <a class="title-header ml-1" [routerLink]="'/manager/study-select'" *ngIf="studySelected">
          <i class=" fas fa-pencil-alt fs18"></i> <span style="margin-left: 50px;">{{studySelected?.study_code}}</span>
        </a>
      </div>
      <div class="user-info cursor-pointer d-flex">
        <ul class="nav justify-content-end h-56px">
          <li class="nav-item pt-2">
            <div ngbDropdown class="d-inline-block" *ngIf="multiLang">
              <a class="nav-link text-white" ngbDropdownToggle>
                <button class="btn-dropdown text-white">
                <i class="fas fa-globe fs18 mr-1"></i>
                {{i18nUse}}
                <i class="fas fa-angle-down"></i>
                </button>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1_1" class="dropdown-menu rounded">
                <div *ngFor="let lang of i18nList" ngbDropdownItem [ngClass]="{'active': lang.code == appConfig.getLang()}"
                  (click)="changeLanguage(lang)">{{lang.name}}</div>
              </div>
            </div>
          </li>
          <li class="nav-item pt-2">
            <div ngbDropdown class="d-inline-block">
                <a class="nav-link text-white" ngbDropdownToggle>
                <button class="btn-dropdown text-white">
                <i class="fas fa-user-circle fs18 mr-1"></i>
                {{ authService.currentUserValue.user.name }}
                <i class="fas fa-angle-down"></i>
                </button>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1_1" class="dropdown-menu rounded">
                <div ngbDropdownItem (click)="changePassword()">
                    <i class="fas fa-key"></i><span class="ml-2">{{ 'COMMON.CHANGE_PASSWORD' |translate }}</span>
                </div>
                <div ngbDropdownItem (click)="logout()">
                    <i class="fas fa-power-off"></i><span class="ml-2">{{ 'COMMON.LOGOUT' | translate }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="content-wrapper" [class]="isToggledNav==true ? 'overlay-show' :'' ">
      <div class="overlay" *ngIf="isToggledNav==true" (click)="isToggledNav =false"></div>
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
