import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../share/utils/custom-validators";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../../share/service/loading.service";
import {ConfirmationModalService} from "../../../share/service/modal/confirmation-modal.service";
import {AuthService} from "../../../services/api-service/auth.service";
import {TranslateService} from '@ngx-translate/core';
import {GlobalDataService} from 'src/app/services/global-data.service';
import {APP_CONFIG} from 'src/app/configs/app-config';
import {I18NList} from 'src/app/configs/i18n-list';
import {QuickAccessForm} from 'src/app/share/utils/quick-access-form';
import { BehaviorService } from 'src/app/services/behavior.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  currentObj = '';
  form: FormGroup;
  isShowForm = false;
  access_token: any;

  showPassword = false;
  showPasswordConfirm = false;
  zone = '';
  appConfig = APP_CONFIG;
  i18nList = I18NList;
  env = environment;
  environmentName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingService,
    private confirmPopup: ConfirmationModalService,
    private authService: AuthService,
    private translateService: TranslateService,
    private globalDataService: GlobalDataService,
    protected behaviorService: BehaviorService
  ) {
    this.zone = route.snapshot.data['zone'];
    this.access_token = this.route.snapshot.params['token'];
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang) {
      lang = this.i18nList[0];
    }

    this.behaviorService.changeLanguage(lang.code);
    this.globalDataService.setMetaTitlePage('RESET_PASSWORD.TITLE');

    this.buildForm();
    this.environmentName = environment.environmentName;
  }

  buildForm(): any {
    this.form = new FormGroup({
      password: new FormControl(null, [CustomValidators.required(), Validators.minLength(8), Validators.pattern("^[a-zA-Z\\d!@#$%^&*()_\\-+=[\\]{}:\"<>?;',./|\\\\]+$")]),
      password_confirmation: new FormControl(null, CustomValidators.required()),
      token: new FormControl(this.access_token),
    }, {validators: this.checkPasswords});
    this.isShowForm = true;
    return this.form;
  }

  update(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading.showAppLoading();
    this.authService.resetPass(this.form.value).subscribe(() => {
        this.loading.hideAppLoading();
        this.confirmPopup.notify('', 'RESET_PASSWORD.RESET_SUCCESS_MESSAGE', '', '', 'RESET_PASSWORD.BACK', () => {
          this.router.navigateByUrl((this.zone ? '/' + this.zone : '') + '/login', {replaceUrl: true}).then();
        });
      }, (error: { success: boolean, message: string }) => {
        this.loading.hideAppLoading();
        this.globalDataService.showErrmessage(error.message);
      }
    )
    ;

  }

  checkPasswords(group: FormGroup): any {
    const password = group.get('password').value;
    const confirmPassword = group.get('password_confirmation').value;

    return password === confirmPassword ? null : {matchPass: true};
  }

  formControl(control: string): AbstractControl {
    return this.form.get(control);
  }

  // tslint:disable-next-line:typedef
  onTrim() {
    if (this.form.get('password').value && this.form.get('password').dirty) {
      this.form.get('password').setValue(this.form.get('password').value.trim());
    }
  }

  formGetErrorControlMsg(controlName: string, errors: Array<string>): string {
    return QuickAccessForm.getErrorControlMsg(this.form, controlName, errors, this.currentObj, this.translateService);
  }

  formIsShowErrorControl(controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(this.form, controlName);
  }

  isShowErrorControl(controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(this.form, controlName);
  }

  getErrorControlMsg(errors: Array<string>, controlName: string): string {
    return QuickAccessForm.getErrorControlMsg(this.form, controlName, errors, 'LOGIN_PAGE.ERRORS', this.translateService);
  }

  getLinkLogin(): string {
    return (this.zone ? '/' + this.zone : '') + '/login';
  }

  changeLanguage(lang: any): void{
    this.behaviorService.changeLanguage(lang.code);
  }
}
