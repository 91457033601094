import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'src/app/services/api-service/auth.service';
import {LoadingService} from 'src/app/share/service/loading.service';
import {ConfirmationModalService} from 'src/app/share/service/modal/confirmation-modal.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../share/utils/custom-validators";
import {QuickAccessForm} from "../../../share/utils/quick-access-form";
import {APP_CONFIG} from 'src/app/configs/app-config';
import {GlobalDataService} from 'src/app/services/global-data.service';
import {I18NList} from 'src/app/configs/i18n-list';
import { BehaviorService } from 'src/app/services/behavior.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  @ViewChild('ngForm') myForm;
  currentObj = '';
  currentURL: string;
  changeLang: string;
  form: FormGroup;

  isShowForm = false;
  appConfig = APP_CONFIG;
  zone = '';
  i18nList = I18NList;
  env = environment;
  environmentName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingService,
    private confirmPopup: ConfirmationModalService,
    private authService: AuthService,
    private globalDataService: GlobalDataService,
    protected translateService: TranslateService,
    protected behaviorService: BehaviorService
  ) {
    this.zone = route.snapshot.data['zone'];
    this.currentURL = window.location.origin + '/reset-password';
    if (this.zone) {
      this.currentURL = window.location.origin + '/' + this.zone + '/reset-password';
    }
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    this.changeLang = lang.code;
    if (!lang) {
      lang = this.i18nList[0];
    }
    this.behaviorService.changeLanguage(lang.code);
    this.globalDataService.setMetaTitlePage('LOGIN_PAGE.RESET_PASSWORD');

    this.buildForm();
    this.environmentName = environment.environmentName;
  }

  buildForm(): any {
    this.form = new FormGroup({
      email: new FormControl(null, [CustomValidators.required(), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]),
      url: new FormControl(this.currentURL),
      lang: new FormControl(this.changeLang),
    });
    this.isShowForm = true;
    return this.form;
  }


  update(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading.showAppLoading();
    this.authService.forgetPass(this.form.value).subscribe(() => {
        this.loading.hideAppLoading();
        this.globalDataService.showSuccess('COMMON.FORGOT_SUCCESS_MESSAGE');
      }, (error: { success: boolean, message: string }) => {
        this.loading.hideAppLoading();
        this.globalDataService.showErrmessage(error.message);
      }
    )
    ;

  }

  formGetErrorControlMsg(controlName: string, errors: Array<string>): string {
    return QuickAccessForm.getErrorControlMsg(this.form, controlName, errors, this.currentObj, this.translateService);
  }

  formIsShowErrorControl(controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(this.form, controlName);
  }

  getLinkLogin(): string {
    return (this.zone ? '/' + this.zone : '') + '/login';
  }

  changeLanguage(lang: any): void {
    this.form.get('lang').setValue(lang.code);
    this.behaviorService.changeLanguage(lang.code);
  }
}
