import {Component, OnDestroy, OnInit,} from '@angular/core';
import {AuthService} from '../../../services/api-service/auth.service';
import {Router} from '@angular/router';
import {UserSession} from '../../../models/user-session.model';
import {NavMenuList} from '../../../configs/menu-list';
import {I18NList} from '../../../configs/i18n-list';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NavMenuInterface} from 'src/app/interfaces/nav-menu.interface';
import {environment} from 'src/environments/environment';
import { APP_CONFIG } from 'src/app/configs/app-config';
import { BehaviorService } from 'src/app/services/behavior.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  preserveWhitespaces: false,
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  isToggledNav = false;
  interval: any;
  itemName = '';
  currentUser: UserSession;
  menuList = NavMenuList.MENU_ADMIN;
  i18nList = I18NList;
  i18nUse = '';
  multiLang = true;
  titleHeader = 'Dashboard';
  env = environment;
  appConfig = APP_CONFIG;
  environmentName: string;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    protected behaviorService: BehaviorService,
    protected globalDataService: GlobalDataService
  ) {
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang){
      lang = this.i18nList[0];
    }
    this.i18nUse = lang.name;

    this.behaviorService.changeLanguage(lang.code);

    this.menuList.map((menu: any) => {
      if (menu.url === this.router.url) {
        this.globalDataService.setMetaTitlePage(menu.name);
      }
    });
    this.environmentName = environment.environmentName;
  }

  changeLanguage(lang): void{
    this.i18nUse = lang.name;
    this.behaviorService.changeLanguage(lang.code);
  }

  changeMenu(menu): void{
    this.globalDataService.setMetaTitlePage(menu.name);
  }

  gotoDashboard(): void{
    const itemHome = this.menuList.find(item => item?.isHome);
    if (itemHome){
      this.globalDataService.setMetaTitlePage(itemHome.name);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  logout(): void {
    this.authService.logout().subscribe(success => {
      if (success) {
        this.router.navigateByUrl('/login', {replaceUrl: true}).then();
      } else {
        this.globalDataService.showErrmessage('ERROR_LOGOUT');
      }
    });
  }
  changePassword(): void{
    this.router.navigateByUrl('/admin/change-password', {replaceUrl: true}).then();
  }

  getItem(items, item): void {
    this.titleHeader = item.name;
    this.itemName = item.name;
  }
}
