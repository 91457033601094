<div class="full-page login-page">
  <div class="">
    <div class="container">
      <div class="row vh-60 justify-content-center align-items-center">
        <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3 ml-auto mr-auto">
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex align-items-center justify-content-center">
              <img class="w-60px h-60px img logo mr-3 mt-1" src="assets/images/logo.png">
              <div class="d-flex flex-column">
                <h1 class="text-nowrap m-0 p-0">{{ 'TITLE_PAGE' | translate }}</h1>
                <h4 class="text-nowrap m-0 p-0">
                  {{ environmentName === 'Development' ? ('Development' | translate) : ('Production' | translate) }}
                </h4>
              </div>
            </div>
          </div>
          <form id="login-form" [formGroup]="form" (ngSubmit)="update()" class="form shadow mt-3" action=""
                method="post" #ngForm="ngForm">
            <div class="card" data-background="color" data-color="blue">
              <div>
                <div class="card-header text-center">
                  <h3 class="card-title">{{'RESET_PASSWORD.TITLE' | translate}}</h3>
                </div>
                <div class="card-content">

                  <div class="form-group">
                    <label>{{'RESET_PASSWORD.NEW_PASSWORD'|translate}}</label>
                    <div class="input-group">
                      <input class="form-control" type="password" formControlName="password"
                             (blur)="onTrim()"
                             [type]="showPassword ? 'text' : 'password'"
                             [ngClass]="{'border border-danger': isShowErrorControl('password')}">
                      <div class="input-group-append">
                        <button type="button" class="input-group-text" (click)="showPassword = !showPassword"><i
                          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'" class="fas fa-eye"></i></button>
                      </div>
                    </div>
                    <div class="text-danger mt-2" *ngIf="ngForm.submitted || ngForm.form.controls.password.touched">
                      <div *ngIf="form.hasError('required','password')">{{'CHANGE_PASSWORD.ERRORS.NEW_PASS_REQUIRED' | translate}}</div>
                      <div *ngIf="form.hasError('minlength','password')">{{'CHANGE_PASSWORD.ERRORS.MINLENGTH' | translate}}</div>
                      <div *ngIf="form.hasError('pattern','password')">{{'CHANGE_PASSWORD.ERRORS.PATTERN' | translate}}</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{'RESET_PASSWORD.NEW_PASSWORD_CONFIRM'|translate}}</label>
                    <div class="input-group">
                      <input class="form-control" formControlName="password_confirmation"
                             [type]="showPasswordConfirm ? 'text' : 'password'"
                             [ngClass]="{'border border-danger': isShowErrorControl('password_confirmation')}">
                      <div class="input-group-append">
                        <button type="button" class="input-group-text" (click)="showPasswordConfirm = !showPasswordConfirm"><i
                          [ngClass]="showPasswordConfirm ? 'fa-eye' : 'fa-eye-slash'" class="fas fa-eye"></i></button>
                      </div>
                    </div>
                    <div class="text-danger mt-2" *ngIf="ngForm.submitted || ngForm.form.controls.password_confirmation.touched || ngForm.form.controls.password_confirmation.dirty">
                      <div
                        *ngIf="form.hasError('required','password_confirmation')">{{'RESET_PASSWORD.ERRORS.NEW_PASSWORD_CONFIRM_REQUIRED' | translate}}
                      </div>
                      <div
                        *ngIf="form.get('password_confirmation').valid && form.hasError('matchPass')">{{'RESET_PASSWORD.ERRORS.CONFIRM'
                        |
                        translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-center">
                <button type="submit" class="btn btn-primary">{{'COMMON.RESET' | translate}}</button>
                <div class="forgot mt-3">
                  <a class="text-center cursor-point"
                     [routerLink]="getLinkLogin()">{{'LOGIN_PAGE.LOGIN' | translate}}</a>
                </div>
              </div>
            </div>
          </form>
          <div class="mt-3">
            <ul class="list-lange text-center">
              <li *ngFor="let lang of i18nList; let i = index;" (click)="changeLanguage(lang)" class="ml-1 pt-1 item">
                <button [ngClass]="{'active': lang.code == appConfig.getLang()}" class="border-0 item">{{ lang.name }}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
