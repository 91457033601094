import { TranslateService } from '@ngx-translate/core';

export const APP_CONFIG = {
  getMessTranslate(translate: TranslateService, screen: string, mesKey: string = '', options: any = {}): any {
    const keyScreen = (screen + '.' + mesKey).toUpperCase();
    const keyCommon = 'COMMON.' + (mesKey).toUpperCase();

    let message = translate.instant(keyScreen, options);
    if (message == keyScreen || message == '') {
      message = translate.instant(keyCommon, options);
      if (message == keyCommon || message == '') {
        message = translate.instant(mesKey.toUpperCase(), options);
        if (message == mesKey || message == '') {
          return mesKey;
        }
      }
    }
    return message;
  },
  handsign: {
    sizeFT: [150, 60],
    sizeS: [200, 100],
    sizeM: [300, 150],
    sizeL: [400, 200],
    sizeHandSignPopup: [665, 250],
  },
  toastOption: {
    positionClass: 'toast-top-right',
    timeOut: 3000
  },
  default_lang: 'ja',
  getLang(): string{
    const lang = localStorage.getItem('use_lang');
    return lang ? lang : this.default_lang;
  },

  permission_list: [
    {id: 1, name: 'MANAGER_ROLES.PERMISSIONS.CONSENT_ADMIN', checked: false},
    {id: 2, name: 'MANAGER_ROLES.PERMISSIONS.CONFIGURE', checked: false},
    {id: 3, name: 'MANAGER_ROLES.PERMISSIONS.ADD_EDIT_DOCUMENT', checked: false},
    {id: 4, name: 'MANAGER_ROLES.PERMISSIONS.ADD_EDIT_SUBJECT', checked: false},
    {id: 10, name: 'MANAGER_ROLES.PERMISSIONS.PHI', checked: false},
    {id: 5, name: 'MANAGER_ROLES.PERMISSIONS.DELETE_SUBJECTS', checked: false},
    {id: 6, name: 'MANAGER_ROLES.PERMISSIONS.AUDIT_LOG', checked: false},
    {id: 7, name: 'MANAGER_ROLES.PERMISSIONS.MONITORING1', checked: false},
    {id: 11, name: 'MANAGER_ROLES.PERMISSIONS.MONITORING2', checked: false},
    {id: 8, name: 'MANAGER_ROLES.PERMISSIONS.REPORT', checked: false},
    {id: 9, name: 'MANAGER_ROLES.PERMISSIONS.READ_ONLY', checked: false},
  ],
  permission_value: {
    CONSENT_ADMIN: 1,
    CONFIGURE: 2,
    ADD_EDIT_DOCUMENT: 3,
    ADD_EDIT_SUBJECT: 4,
    DELETE_SUBJECTS: 5,
    AUDIT_LOG: 6,
    MONITORING1: 7,
    MONITORING2: 11,
    REPORT: 8,
    READ_ONLY: 9,
    PHI: 10,
  },
  permission_link: {
    view_setting: [1, 2, 9],
    save_setting: [1, 2],
    sites: [1, 9],
    site: [1],
    documents: [1, 3, 9],
    document: [1, 3],
    document_monitor: [1, 7, 9, 11],
    personal_detail: [1],
    encode_subject: [1],
    monitoring: [1, 7, 11],
    users: [1, 9],
    user: [1],
    subjects: [1, 4, 5, 9],
    subject_add_edit: [1, 4],
    subject_delete: [1, 5],
    roles: [1, 9],
    role: [1],
    config_logs: [1, 8, 9],
    data_audit_logs: [1, 6, 8, 9],
    manager_common: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11],
    document_monitor_download_all: [1, 7, 9],

    tablet_app_common: [1, 10],
    tablet_app_subjects: [1, 4],
    tablet_app_documents: [1],
    tablet_app_consenting: [1, 10],
    study_allow_consent: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11],
    study_allow_consenting: [1, 2, 3, 4, 5, 6, 7, 8, 11],
  },
  tablet_screen: [
    {value: 'tablet-app', name: 'DASHBOARD'},
    {value: 'start', name: 'START_CONSENT'},
    {value: 'confirm', name: 'SUBJECT_CONFIRM'},
    {value: 'doctor-sign', name: 'DOCTOR_SIGN'},
    {value: 'subjects', name: 'SUBJECTS_LIST'},
    {value: 'documents', name: 'DOCUMENTS_LIST'}
  ],
  attach_file_typs: [
    { value: 1, label: 'IMAGE', format: ['gif', 'png', 'jpg', 'jpeg', 'jxr', 'jpe'] },
    { value: 2, label: 'PDF', format: ['pdf'] },
    { value: 3, label: 'DOC', format: ['doc', 'docx'] },
    { value: 4, label: 'CSV', format: ['csv'] },
    { value: 9, label: 'IMAGE', format: [] }
  ],
  attach_file_image: 1,
  hideCharacterSubject: '********'
};
