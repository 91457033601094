<div class="container">
  <div class="row vh-50 justify-content-center align-items-center">
    <div class="col-md-6 col-12">
      <form class="box-form" [formGroup]="form" (ngSubmit)="actionChangePass()" #ngForm="ngForm">
        <h3 class="text-center">{{'CHANGE_PASSWORD.TITLE' | translate}}</h3>
        <div class="mb-3">
          <label for="current_password" class="form-label">{{'CHANGE_PASSWORD.CURRENT_PASSWORD' | translate}}</label>
          <div class="form-group" style="display: flex;">
            <input type="password" class="form-control" id="current_password" formControlName="current_password"
                   [type]="showPassword ? 'text' : 'password'"
                   [ngClass]="{'border border-danger': (ngForm.form.controls.current_password.touched || ngForm.submitted ) && formControl('current_password').errors}">
            <button type="button" (click)="showPassword = !showPassword" class="input-group-addon" style="width: 40px;">
                            <i class="fa fa-eye-slash" aria-hidden="true"
                               [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"
                               style="padding-top: 5px;"></i>
                        </button>
          </div>

          <div class="text-danger" *ngIf="ngForm.submitted || ngForm.form.controls.current_password.touched">
            <div
              *ngIf="form.hasError('required', 'current_password')">{{'CHANGE_PASSWORD.ERRORS.PASS_REQUIRED' | translate}}</div>
          </div>
        </div>

        <div class="mb-3">
          <label for="new_password" class="form-label">{{'CHANGE_PASSWORD.MEW_PASSWORD' | translate}}</label>
          <div class="form-group" style="display: flex;">
            <input type="password" class="form-control" id="new_password" formControlName="password"
                   [type]="showNewPass ? 'text' : 'password'"
                   [ngClass]="{'border border-danger': (ngForm.submitted ||ngForm.form.controls.password.touched ) && formControl('password').errors}">
            <button type="button" (click)="showNewPass = !showNewPass" class="input-group-addon" style="width: 40px;">
                            <i class="fa fa-eye-slash" aria-hidden="true"
                               [ngClass]="{'fa-eye': showNewPass, 'fa-eye-slash': !showNewPass}"
                               style="padding-top: 5px;"></i>
                        </button>
          </div>
          <div class="text-danger" *ngIf="ngForm.submitted || ngForm.form.controls.password.touched">
            <div *ngIf="form.hasError('required','password')">{{'CHANGE_PASSWORD.ERRORS.NEW_PASS_REQUIRED' | translate}}</div>
            <div *ngIf="form.hasError('minlength','password')">{{'CHANGE_PASSWORD.ERRORS.MINLENGTH' | translate}}</div>
            <div *ngIf="form.hasError('pattern','password')">{{'CHANGE_PASSWORD.ERRORS.PATTERN' | translate}}</div>
          </div>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">{{'CHANGE_PASSWORD.NEW_PASSWORD_COMFIRM' | translate}}</label>
          <div class="form-group" style="display: flex;">
            <input type="password" class="form-control" id="password" formControlName="password_confirmation"
                   [type]="showPassConfirm ? 'text' : 'password'"
                   [ngClass]="{'border border-danger':(ngForm.submitted || ngForm.form.controls.password_confirmation.touched ) && formControl('password_confirmation').errors}">
            <button type="button" (click)="showPassConfirm = !showPassConfirm" class="input-group-addon" style="width: 40px;">
                            <i class="fa fa-eye-slash" aria-hidden="true"
                               [ngClass]="{'fa-eye': showPassConfirm, 'fa-eye-slash': !showPassConfirm}"
                               style="padding-top: 5px;"></i>
                        </button>
          </div>
          <div class="text-danger" *ngIf="ngForm.submitted || ngForm.form.controls.password_confirmation.touched || ngForm.form.controls.password_confirmation.dirty">
            <div
              *ngIf="form.hasError('required','password_confirmation')">{{'CHANGE_PASSWORD.ERRORS.NEW_PASS_CONFIRM_REQUIRED' |
              translate}}</div>
            <div
              *ngIf="form.get('password_confirmation').valid && form.hasError('matchPass')">{{'CHANGE_PASSWORD.ERRORS.CONFIRM' | translate}}</div>
          </div>
        </div>

        <div class="text-center">
          <button class="w-60 btn btn-info" type="submit">{{'CHANGE_PASSWORD.UPDATE' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
