import { Injectable, NgZone } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { AuthService } from '../../services/api-service/auth.service';
import { LoadingService } from '../../share/service/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '../../share/service/modal/confirmation-modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalDataService } from '../../services/global-data.service';
import { NavMenuList } from "../../configs/menu-list";
import { ManagerStudySelectApi } from 'src/app/services/api-service/manager-study-select.api.service';

@Injectable()
export class ErrorInterceptorHelper implements HttpInterceptor {
  constructor(
    private router: Router,
    private zone: NgZone,
    private authService: AuthService,
    private loading: LoadingService,
    private translate: TranslateService,
    private confirmPopup: ConfirmationModalService,
    private globalDataService: GlobalDataService,
    private modalService: NgbModal,
    protected studySelectApi: ManagerStudySelectApi,
    private routerActive: ActivatedRoute
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const zoneURL = this.globalDataService.zoneURL;
        const role = JSON.parse(localStorage.getItem('role'));
        this.loading.hideAppLoading();
        if (err.status === 401 || err.status === 423) {
          // 423: account unactive and deleted (lock)
          this.confirmPopup.notify('', 'ERRORS.UNAUTHENTICATED', '', '', 'COMMON.OK', () => {
            this.modalService.dismissAll();
            this.globalDataService.clearForm();
            this.authService.gotoLogin(this.globalDataService.zoneURL);
          });
        }
        else if (err?.error?.message === 'ER0030' || err?.error?.message === 'ER0031') { // not assign to study
          this.authService.getUserInfo().subscribe((userSession) => {
            this.globalDataService.clearForm();
            this.modalService.dismissAll();
            if (zoneURL === 'manager') {
              let goToScreen = '';
              err?.error?.message === 'ER0030' ? goToScreen = 'manager/study-select' : goToScreen = 'manager';
              this.confirmPopup.notify('', `ERRORS.${err?.error?.message}`, '', '', 'COMMON.OK', () => {
                this.studySelectApi.clearStudyWorking();
                this.router.navigateByUrl(goToScreen, {replaceUrl: true}).then();
              });
            }else if (zoneURL === 'tablet-app') {
              this.confirmPopup.notify('', `ERRORS.${err?.error?.message}`, '', '', 'COMMON.OK', () => {
                sessionStorage.removeItem('study_id');
                sessionStorage.removeItem('site_id');
                sessionStorage.removeItem('studySelect');
                sessionStorage.removeItem('siteSelect');
                sessionStorage.removeItem('subject_id');
                this.router.navigateByUrl(`tablet-app`, {replaceUrl: true}).then();
              });
            }
          });
        }else if (err?.error?.message === 'ER0040') { // not have permission
          this.authService.getUserInfo().subscribe((userSession) => {
            this.globalDataService.clearForm();
            this.modalService.dismissAll();
            this.confirmPopup.notify('', `ERRORS.ER0040`, '', '', 'COMMON.OK', () => {
              sessionStorage.removeItem('study_id');
              sessionStorage.removeItem('site_id');
              sessionStorage.removeItem('subject_id');
              this.router.navigateByUrl(zoneURL, {replaceUrl: true}).then();
            });
          });
        }
        else if (err.status === 0) {
          this.modalService.dismissAll();
          this.confirmPopup.notify('', 'COMMON.ERRORS.CONNECTION');
        }
        return throwError(err.error);
      })
    );
  }
}
