import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../share/utils/custom-validators';
import { ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '../../../share/service/loading.service';
import {AuthService} from '../../../services/api-service/auth.service';
import {HandleError, HandleErrorModel} from '../../../models/handle-error.model';
import {TranslateService} from '@ngx-translate/core';
import {QuickAccessForm} from '../../../share/utils/quick-access-form';
import { UserSubjectInterface} from 'src/app/interfaces/user-session.interface';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { I18NList } from 'src/app/configs/i18n-list';
import { APP_CONFIG } from 'src/app/configs/app-config';
import { BehaviorService } from 'src/app/services/behavior.service';
import { ManagerStudySelectApi } from 'src/app/services/api-service/manager-study-select.api.service';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', CustomValidators.requiredPassWord()),
    zone: new FormControl(''),
  });
  showPassword = false;
  submitted = false;
  errorLogin: any;
  zone = '';
  i18nList = I18NList;
  appConfig = APP_CONFIG;
  environmentName: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingService,
    private authService: AuthService,
    protected globalDataService: GlobalDataService,
    protected translateService: TranslateService,
    protected behaviorService: BehaviorService,
    protected studySelectApi: ManagerStudySelectApi,
    protected errorService: ErrorService,
  ) {
    this.zone = this.route.snapshot.data['zone'];
    this.loginForm.controls.zone.setValue(this.zone);
    this.globalDataService.zoneURL = this.zone;
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang){
      lang = this.i18nList[0];
    }

    this.behaviorService.changeLanguage(lang.code);
    this.globalDataService.setMetaTitlePage('LOGIN_PAGE.LOGIN');
    this.globalDataService.clearForm();
    this.studySelectApi.clearStudyWorking();
    this.environmentName = environment.environmentName;
  }

  login(): void {
    this.submitted = true;
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }
    this.loading.showAppLoading();
    this.loginForm.get('email').setValue(this.loginForm.value.email.trim());
    const params = this.loginForm.value;
    this.authService.login(params).subscribe((val: UserSubjectInterface | any) => {
      this.loading.hideAppLoading();
      if (!val.token){
        this.globalDataService.showErrmessage(val?.message, 'LOGIN', '.');
      }else{
        this.authService.getUserInfo().subscribe(v => {
          if (this.zone) {
            this.router.navigateByUrl('/' + this.zone, { replaceUrl: true }).then();
          }
          else if (v?.is_admin) {
            this.router.navigateByUrl('/admin', { replaceUrl: true }).then();
          }
          else {
            this.router.navigateByUrl('/manager', { replaceUrl: true }).then();
          }
        });
      }
    }, (er: HandleErrorModel) => {
      this.loading.hideAppLoading();
      console.log('loginFail');
      this.loginFail(er);
      
      if (Number.parseInt(er.status) == 403) {
        this.globalDataService.showError(this.errorService.getErrorsMessage(er.message));
      
      const handleError = new HandleError(er);
      this.errorLogin = handleError.getErrorCodeStr(this.translateService);
      }
    });
  }

  // @ts-ignore
  loginFail(er): HandleErrorModel {
    if (er.message) {
      if (this.zone) {
        this.router.navigateByUrl((this.zone ? '/' + this.zone : '') + '/login', {replaceUrl: true}).then();
      }
    }
  }


  getLinkForgot(): string {
    return (this.zone ? '/' + this.zone : '') + '/forgot-password';
  }

  onTrim(): void {
    if (this.loginForm.get('email').value && this.loginForm.get('email').dirty)
    {
      this.loginForm.get('email').setValue(this.loginForm.get('email').value.trim());
    }
  }

  // convenience getter for easy access to form fields
  form(control: string): AbstractControl {
    return this.loginForm.get(control);
  }

  isShowErrorControl(controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(this.loginForm, controlName);
  }

  getErrorControlMsg(errors: Array<string>, controlName: string): string {
    return QuickAccessForm.getErrorControlMsg(this.loginForm, controlName, errors, 'LOGIN_PAGE.ERRORS', this.translateService);
  }

  changeLanguage(lang: any): void{
    this.behaviorService.changeLanguage(lang.code);
  }
}
